import React from 'react';
import { useIntl } from 'react-intl-next';

import type { RendererAppearance } from '@atlaskit/renderer';

import UFOSegment from '@atlassian/react-ufo/segment';

import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { CONTENT_RENDERER_METRIC } from '@confluence/view-content-perf-metrics';

import type { ContentBodyType } from '../__types__';
import { TinyMCEServerFullRenderer } from '../TinyMCEServerFullRenderer';
import { TinyMCEClientRenderer } from '../TinyMCEClientRenderer';

import { StyledRenderer } from './StyledRenderer';

type PageContentRenderTinyProps = {
	contentAppearance: RendererAppearance;
	contentBody: ContentBodyType;
	contentId: string;
	getLegacyContentExtensions?: () => any;
	markerRefs: string[];
	queryHash?: string;
	renderPerformanceMetrics: (props) => (...args: any) => JSX.Element;
	revision: string;
	setRootEl: (el: HTMLDivElement | null) => void;
	lastModifiedDate?: string | null;
};
const PageContentRendererTinyInternal = ({
	contentAppearance,
	contentBody,
	contentId,
	getLegacyContentExtensions,
	markerRefs,
	queryHash,
	renderPerformanceMetrics,
	revision,
	setRootEl,
	lastModifiedDate,
}: PageContentRenderTinyProps) => {
	const intl = useIntl();
	const tinyBody = contentBody?.value || '';
	const webresource = contentBody?.webresource;
	const css = webresource?.tags?.css || '';
	const js = webresource?.tags?.js || '';
	const superbatchJs = webresource?.superbatch?.tags?.js || '';
	const superbatchCss = webresource?.superbatch?.tags?.css || '';

	return (
		<>
			<PageSegmentLoadStart key={contentId} metric={CONTENT_RENDERER_METRIC} />
			<StyledRenderer
				ref={setRootEl}
				data-test-appearance={contentAppearance}
				markerRefs={markerRefs}
				data-testid="pageContentRendererTinyRendererTestId"
				intl={intl}
			>
				{process.env.REACT_SSR ? (
					<TinyMCEServerFullRenderer
						body={tinyBody}
						superbatchCss={superbatchCss}
						superbatchJs={superbatchJs}
						css={css}
						js={js}
						contentId={contentId}
					/>
				) : (
					<TinyMCEClientRenderer
						body={tinyBody}
						scrollTo={queryHash || ''}
						isAnonymous={false}
						jsDependencies={js}
						cssDependencies={css}
						renderWhenReady={renderPerformanceMetrics({
							contentId,
							isFabric: false,
							revision,
							lastModifiedDate,
						})}
						getLegacyContentExtensions={getLegacyContentExtensions}
					/>
				)}
			</StyledRenderer>
		</>
	);
};

export const PageContentRendererTiny: typeof PageContentRendererTinyInternal = (props) => {
	return (
		<UFOSegment name="page-content-renderer-tiny">
			<PageContentRendererTinyInternal {...props} />
		</UFOSegment>
	);
};
